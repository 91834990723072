.navbar-body {
  display: flex;
  justify-content: space-between;
  padding-left: 3%;
  padding-top: 1%;
  align-items: center;
  padding-right: 3%;
}

.page-name {
  color: #fa5805;
}
.navButton {
  text-decoration: none;
  color: black;
  border: solid 3px #fa5805;
  margin-left: 5px;
  padding-left: 20px;
  padding-right: 20px;
  align-self: center;
  padding-top: 2px;
  background-color: transparent;
  padding-bottom: 2px;
  margin-right: 5px;
  font-family: "quantico";
  transition: 700ms;
}

.navButton:hover {
  cursor: pointer;
  background-color: #fa5805;
  color: white;
  transition: 400ms;
}
@media screen and (min-width: 600px) {
  .small-navbar {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .small-navbar {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100vw;
  }
  .navbar-page-name-wrapper {
    align-self: center;
  }
  .small-nav-buttons-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .navButton {
    width: 60vw !important ;
    padding-left: 0px !important;
    padding-right: 0px !important;
    text-align: center;
    margin-bottom: 10px;
    align-items: center !important;
  }
  .navbar-body {
    display: none !important;
  }
}

.big-nabvar-buttons-wrapper {
  display: flex;
  flex-wrap: wrap;
}