.researches-card-body {
    border-color: #57397e;
    border-style: solid;
    border-width: 3px;
    padding-left: 10px;
    padding-top: 5px;
    height: 60vh !important;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 80%;
    margin-top: 10px;
}

@media screen and (max-width: 600px){
    .researches-card-body{
        border: none !important;
        width: 90vw;
    }
}
.researches-card-body::-webkit-scrollbar {
    width: 7px;

}

.researches-card-body::-webkit-scrollbar-track {
    border-radius: 10px;
    
}

.researches-card-body::-webkit-scrollbar-thumb {
    background-color: #fa5805;
    height: 40px;

}
