.avaliacao-narrativas-input {
  width: 250px;
  border: 2px solid #57397e;
  background-color: transparent;
  margin-bottom: 5vh;
  height: 30px;
  padding-left: 5px;
  font-family: quantico;
}

.avaliacao-narrativas-select {
  width: 259px;
  border: 2px solid #57397e;
  background-color: transparent;
  margin-bottom: 5vh;
  height: 34px;
  padding-left: 5px;
  font-family: quantico;
}


.avaliacao-narrativas-button {
  background-color: transparent;
  border: 2px solid #fa5805;
  width: 85px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: quantico;
  transition: 500ms;
}

.avaliacao-narrativas-button:hover {
  background-color: #fa5805;
  color: white;
  cursor: pointer;
  transition: 500ms;
}
.avaliacao-input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25vh;
}

.narrativas-a-analisar-wrapper {
  display: flex;
  flex-direction: column;
  border: 2px solid #fa5805;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-right: 30px;
  padding: 15px;
  margin-top: 50px;
}

.narrativas-aprovadas-wrapper {
  display: flex;
  flex-direction: column;
  border: 2px solid #57397e;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-right: 30px;
  padding: 15px;
}

.narrativas-recusadas-wrapper {
  display: flex;
  flex-direction: column;
  border: 2px solid #6c6c6c;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-right: 30px;
  padding: 15px;
}

.recusada-narrativa-body {
  display: flex;
  flex-direction: column;
}

.analisar-narrativa-body {
  display: flex;
  flex-direction: column;
}

.aprovadas-narrativa-body {
  display: flex;
  flex-direction: column;
}

.list-name-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 5px;
}

.narrativa-element-divider {
  height: 2px;
  margin-top: 10px;
  margin-bottom: 5px;
  background-color: #a2a2a2;
  width: 95%;
  align-self: center;
}

.city-name-element {
  margin-left: 3px;
  padding-right: 3px;
  border-right: 2px solid #6c6c6c;
}

.city-name-element-last {
  margin-left: 3px;
}

.analisar-button-wrapper {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
}

.aprovadas-button-wrapper {
  display: flex;
  justify-content: flex-start;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
}

.recusada-button-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
}
.approve-button {
  width: 90px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: transparent;
  border: 2px solid #57397e;
  font-family: quantico;
  transition: 500ms;
}

.approve-button:hover {
  background-color: #57397e;
  color: white;
  cursor: pointer;
  transition: 500ms;
}
.refuse-button {
  width: 90px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: transparent;
  border: 2px solid #fa5805;
  font-family: quantico;
  transition: 500ms;
}

.refuse-button:hover {
  background-color: #fa5805;
  color: white;
  transition: 500ms;
  cursor: pointer;
}


.publicacoes-header{
  margin-left: 25px;
  margin-bottom: 10px;
}

.narrativas-avaliacao-header{
  margin-left: 30px;
}