.quiz-card-body {
  height: 55vh;
}

.quiz-card-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 5px;
  margin-top: 10px;
}

.quiz-page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 10;
  flex-wrap: wrap;
  overflow-y: hidden;
  overflow-x: scroll;
  margin-top: 0%;
  margin-left: 11.6vw;
}

.quiz-page-wrapper::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}

.quiz-page-wrapper::-webkit-scrollbar-track {
  border-radius: 10px;
}

.quiz-page-wrapper::-webkit-scrollbar-thumb {
  background-color: #fa5805;
  height: 10px;
}
.quiz-info-card-body {
  height: 55vh;
  width: 200px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  margin-right: 15px;
  border: 2px #57397e solid;
}

.quiz-info-card-body::-webkit-scrollbar {
  width: 7px;
}

.quiz-info-card-body::-webkit-scrollbar-track {
  border-radius: 10px;
}

.quiz-info-card-body::-webkit-scrollbar-thumb {
  background-color: #fa5805;
  height: 40px;
}

.quiz-info-card-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.card-title-divider {
  height: 1.5px;
  margin-top: 5px;
  background-color: #6c6c6c;
  width: 90%;
}
.card-title {
  color: #454444;
}

.info-text-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 7.5px;
  margin-right: 7.5px;

  margin-bottom: 15px;
}

.info-text-line-date {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 7.5px;
}

.info-text-card {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.h5-title-card-info {
  font-weight: bold;
}

.info-text-items {
  margin-left: 15px;
  margin-bottom: 15px;
}

.info-text-line-items {
  display: flex;
  flex-direction: row;
}

.quiz-button-selected {
  padding-left: 20px;
  font-family: "quantico";
  padding-right: 20px;
  padding-top: 7px;
  width: 200px;
  padding-bottom: 7px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: #fa5805;
  border-color: #fa5805;
  border-style: solid;
  border-width: 3px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 600ms;
  color: white;
}

.quiz-button-selected:hover {
  cursor: pointer;
  transition: 400ms;
}

.quiz-button {
  padding-left: 20px;
  font-family: "quantico";
  padding-right: 20px;
  padding-top: 7px;
  width: 200px;
  transition: 600ms;
  padding-bottom: 7px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: transparent;
  border-color: #fa5805;
  border-style: solid;
  border-width: 3px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quiz-button:hover {
  cursor: pointer;
  transition: 400ms;
  color: white;
  background-color: #fa5805;
}

.quiz-buttons-wrapper {
  display: flex;
  position: absolute;
  flex-direction: row;
}

.quiz-buttons-wrapper-right-side {
  display: flex;
  margin-bottom: 15px;
  flex-direction: row;
  height: 50px;
  justify-content: center;
  height: 50px;
}

.narrativa-body-wrapper {
  display: flex;
  flex-direction: row;
  flex: 12;
  margin-top: 2%;
}

.narrativa-left-side {
  display: flex;
  flex: 4;
  margin-left: 5%;
}

.narrativa-right-side {
  display: flex;
  flex-direction: column;
  flex: 8;
  margin-right: 5%;
}

.narrativa-cities-details-body {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.narrativa-cities-list-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50px;
  height: 100%;
}

.narrativa-city-element {
  height: 40px;
  width: 40px;
  display: flex;
  transition: 600ms;
  align-items: center;
  justify-content: center;
  border: 3px #57397e solid;
}

.narrativa-city-element:hover {
  background-color: #57397e;
  color: white;
  transition: 400ms;
  cursor: pointer;
}

.narrativa-city-element-selected {
  height: 40px;
  width: 40px;
  display: flex;
  transition: 600ms;
  color: white;
  align-items: center;
  justify-content: center;
  background-color: #57397e;
  border: 3px #57397e solid;
}

.narrativa-city-element-selected:hover {
  cursor: pointer;
}

.narrativa-city-details {
  border: 3px #57397e solid;
  width: 80%;
  height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-left: -7px;
}

.narrativa-city-details::-webkit-scrollbar-track {
  border-radius: 10px;
}

.narrativa-city-details::-webkit-scrollbar-thumb {
  background-color: #fa5805;
  height: 40px;
}

.narrativa-city-details::-webkit-scrollbar {
  width: 7px;
}

.narrativa-city-details::-webkit-scrollbar-track {
  border-radius: 10px;
}

.narrativa-city-details::-webkit-scrollbar-thumb {
  background-color: #fa5805;
  height: 40px;
}
.card-details-header {
  display: flex;
  justify-content: center;
  border-bottom: 3px #57397e solid;
}

.card-details-narrativa-wrapper {
  overflow-y: scroll;
}

.card-details-narrativa-wrapper::-webkit-scrollbar {
  width: 7px;
}

.card-details-narrativa-wrapper::-webkit-scrollbar-track {
  border-radius: 10px;
}

.card-details-narrativa-wrapper::-webkit-scrollbar-thumb {
  background-color: #fa5805;
  height: 40px;
}

.narrativa-input {
  width: 60vw;
  border: #57397e 3px solid;
  height: 25px;
  padding-left: 5px;
  font-family: quantico;
}

.narrativa-text-area {
  max-width: 60vw;
  min-width: 60vw;
  border: #57397e 3px solid;
  height: 25px;
  padding-left: 5px;
  min-height: 50px;
}

.narrativa-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5vh;
}

.narrativa-button {
  padding-left: 25px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 25px;
  background-color: transparent;
  transition: 600ms;
  font-family: quantico;
  border: #57397e 3px solid;
}

.narrativa-button-disabled {
  padding-left: 25px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 25px;
  background-color: transparent;
  transition: 600ms;
  font-family: quantico;
  border: #808080 3px solid;
}

.narrativa-button-disabled:hover {
  cursor: not-allowed;
  transition: 400ms;
}

.narrativa-button:hover {
  background-color: #57397e;
  color: white;
  cursor: pointer;
  transition: 400ms;
}
.small-cards-spacing {
  margin-top: 8vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media screen and (max-width: 600px) {
  .quiz-page-wrapper {
    margin-top: 10vh;
  }
  .quiz-page-wrapper {
    margin-left: 5vw;
  }
  .quiz-card-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-left: 5px;
  }

  .quiz-info-card-body {
    height: 55vh;
    width: 250px;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    margin-left: 15px;
    border: 2px #57397e solid;
  }

  .quiz-button-selected {
    width: 42vw;
  }

  .quiz-button {
    width: 42vw;
  }
  .narrativa-left-side {
    display: none !important;
  }
  .small-cards-spacing {
    margin-top: 100px;
    display: flex;
    flex-direction: row;
  }
  .narrativa-right-side {
    display: flex;
    flex-direction: column;
    margin-right: 0% !important;
    align-items: center !important;
  }

  .quiz-buttons-wrapper-right-side {
    margin-top: 10vh;
  }

  .narrativa-input {
    width: 85vw;
  }

  .narrativa-text-area {
    max-width: 85vw;
    min-width: 85vw;
  }
}

.info-img-wrapper {
  width: 85%;
  height: auto;
  display: flex;
  align-self: center;
}

.image-modal-content {
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-modal-content img {
  height: 60vh;
  width: auto;
}

@media screen and (max-width: 600px) {
  .image-modal-content img {
    height: auto;
    width: 80vw;
  }
}

.text-description-card {
  padding-left: 10px;
}
