.city-item-wrapper {
  height: 50px;
  border-bottom-width: 2px;
  display: flex;
  align-items: center;
  border-top-width: 0px;
  border-style: solid;
  border-color: #57397e;
  border-left: none;
  border-right: none;
  padding-left: 25px;
  transition: 700ms;
  background-color: transparent;
}

.city-item-wrapper-selected {
  height: 50px;
  border-bottom-width: 2px;
  display: flex;
  align-items: center;
  border-top-width: 0px;
  border-style: solid;
  border-color: #57397e;
  border-left: none;
  border-right: none;
  padding-left: 25px;
  background-color: #57397e;
  color: white;
}

.city-item-wrapper-selected:hover {
    cursor: pointer;
}

.city-item-wrapper:hover {
  background-color: #57397e;
  color: white;
  transition: 400ms;
  cursor: pointer;
  
}
