.arrow-up {
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    
    border-bottom: 5px solid black;
  }
  .triangle{
border-style: solid;
border-color: aqua;
border-width: 10px;
  }