.city-details-body {
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  transition: 600ms;
}

.cities-details-data-wrapper {
  display: flex;
  background-color: transparent;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 2px #57397e;
  width: 100%;
  flex: 12;
  padding-top: 5px;
  padding-bottom: 5px;
  align-items: center;
}

.cities-details-data-wrapper-bottom {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  justify-content: space-between;
  border-bottom: solid 2px #57397e;
  width: 90%;
  flex: 12;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
}

.left-data-text {
    font-weight: bold;
    display: flex;
    flex: 6;
padding-left: 20px;
}

.right-data-text {
padding-right: 20px;
text-align: end;
flex: 6;
flex-wrap: wrap;
display: flex;
justify-content: flex-end;
align-items: center;
flex-direction: row;
min-height: 70px;
}

.cities-details-title{
  padding-left: 3%;
}