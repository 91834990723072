.instructions-wrapper {
  margin-top: 5%;
  margin-left: 5%;
  display: flex;
  flex: 12;
}

.instructions-title {
  color: #fa5805;
  transition: 500ms;
  font-weight: bold;
}

.instructions-title:hover {
  cursor: pointer;
}

.instructions-text {
  text-align: center;
}

.instructions-sub-title {
  color: #454444;
  transition: 500ms;
}

.instructions-sub-title:hover {
  cursor: pointer;
}
.instructions-title-wrapper {
  display: flex;
  flex-direction: column;
  flex: 2;
  margin-top: 14.5%;
}
.instructions-cards-titles {
  color: #454444;
}

.instructions-cards-wrapper {
  display: flex;
  flex: 10;
  margin-right: 5%;
  margin-left: 5%;
  justify-content: space-between;
}

.compare-cards-wrapper {
  display: flex;
  flex-direction: column;
  flex: 10;
  margin-right: 5%;
  margin-left: 5%;
  margin-top: 9.8%;
  align-items: center;
  justify-content: center;
}

.instruction-cards-elements-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20vw;
}

.instructions-arranjo-box-wrapper {
  display: flex;
  flex-direction: row;
  width: 20vw;
  margin-top: 15px;
  margin-bottom: 5px;
  justify-content: space-evenly;
}

.instructions-narrativa-box-wrapper {
  display: flex;
  flex-direction: column;
  width: 20vw;
  margin-top: 15px;
  margin-bottom: 5px;
  justify-content: space-evenly;
  height: 20vh;
  align-items: center;
  background-color: #57397e;
}

.instructions-arranjo-box {
  background-color: #57397e;
  height: 20vh;
  width: 4vw;
}

.instructions-arrows-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.correct-direction-arrow {
  margin-bottom: 15px;
}
.reverse-arrow {
  transform: rotate(0deg) scaleX(-1);
  margin-top: 15px;
}

.instructions-white-line {
  height: 5px;
  background-color: white;
  width: 80%;
}

.compare-radio-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 35%;
  margin-top: 2%;
}

.compare-fake-radio-buttons {
  display: flex;
  align-items: center;
}

.compare-fake-radio-buttons:hover {
  cursor: pointer;
}

.compare-fake-radio-indicator-selected {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #fa5805;
  margin-right: 7px;
  border: solid #fa5805 1.5px;
  transition: 600ms;
}

.compare-fake-radio-indicator {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: transparent;
  margin-right: 7px;
  border: solid #fa5805 1.5px;
  transition: 400ms;
}

.compare-button {
  margin-top: 30px;
  border: solid #fa5805 3px;
  width: 150px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 600ms;
  font-family: quantico;
  background-color: transparent;
}

.compare-button:hover {
  cursor: pointer;
  background-color: #fa5805;
  color: white;
  transition: 400ms;
}

.error-max-words {
  color: #f71e1e;
  font-weight: bold;
}

@media screen and (min-width: 600px) {
  .instructions-wrapper-small {
    display: none !important;
  }
}

@media screen and (max-width: 600px) {
  .instructions-wrapper {
    display: none !important;
  }

  .instructions-cards-wrapper {
    flex-direction: column !important;
    align-items: center !important;
  }

  .instructions-arranjo-box-wrapper {
    width: 70vw !important;
  }

  .instructions-arranjo-box {
    width: 16.5vw !important;
  }

  .instructions-text {
    width: 75vw !important;
  }

  .instructions-narrativa-box-wrapper {
    width: 45vw !important;
  }

  .small-compare-title{
    text-align: center;
  }

  .compare-radio-button-wrapper{
    flex-direction: column !important;
    height: 150px;
    justify-content: space-evenly;
  }
}

.small-option-button-selected {
  width: 20px;
  height: 20px;
  transition: 600ms;
  background-color: #fa5805;
  border-radius: 50%;
}

.small-option-button {
  width: 20px;
  height: 20px;
  transition: 600ms;
  background-color: #454444;
  border-radius: 50%;
}

.small-option-button-wrappers {
  display: flex;
  margin-top: 30px;
  margin-bottom: 20px;
  width: 50px;
  align-self: center;
  justify-content: space-between;
}

.instructions-wrapper-small {
  justify-content: center;
  display: flex;
  flex-direction: column;
}
