* {
  margin: 0;
  padding: 0;
}

*:focus {
  outline: 0;
}
body {
  margin: 0;
  font-family: quantico;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::after {
  background-image: url("./assets/images/background/MANDALA-URBANA\ 2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
  left: 0;
  opacity: 0.05;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: -1;
  content: "";
}

.home-page-body {
  display: flex;
  flex: 12;
  overflow: hidden;
}

@media screen and (max-width: 600px) {
  .bottom-buttons-wrapper {
    display: none !important;
  }
  .home-page-body {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .left-side-page-wrapper {
    padding-left: 0vw !important;
    padding-top: 10vh !important;
    padding-right: 0vw !important;
    flex-direction: column;
    display: flex;
    align-items: center !important;
  }

  .small-text-wrapper {
    transition: 500ms;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }

  .right-side-page-wrapper {
    display: none !important;
  }

  .home-page-title {
    margin-bottom: 10px;
    color: #fa5805;
    line-height: 35px !important;
  }

  .home-page-title-wrapper {
    color: #fa5805;
    margin-bottom: 20px;
  }

  .home-page-buttons {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 7px;
    width: 80% !important;
    padding-bottom: 7px;
    background-color: transparent;
    border-color: #fa5805;
    border-style: solid;
    margin-top: 5px;
    margin-bottom: 5px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    transition: 700ms;
    z-index: 100;
  }

  .home-page-buttons:hover {
    cursor: pointer;
    background-color: #fa5805;
    color: white;
  }
}
.left-side-page-wrapper {
  padding-left: 3vw;
  padding-top: 3vh;
  flex: 14;
  flex-direction: column;
  display: flex;
  padding-right: 10%;
}

.cell-indicators-wrapper {
  display: flex;
  flex-direction: row;
  width: 70px;
  justify-content: space-evenly;
  margin-top: 20px;
  margin-bottom: 40px;
}
.cell-indicator-selected {
  width: 15px;
  height: 15px;
  background-color: #fa5805;
  transition: 500ms;
  border-radius: 50%;
}
.cell-indicator {
  width: 15px;
  height: 15px;
  transition: 500ms;
  border-radius: 50%;
  background-color: #454444;
}
.right-side-page-wrapper {
  flex: 14;
  margin-top: -24%;
  margin-right: -20%;
  overflow: hidden;
  flex-direction: column;
  display: flex;
}

.home-page-title {
  margin-bottom: 10px;
  color: #fa5805;
  line-height: 25px;
}

.home-page-buttons {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 7px;
  width: 50%;
  padding-bottom: 7px;
  background-color: transparent;
  border-color: #fa5805;
  border-style: solid;
  margin-top: 5px;
  margin-bottom: 5px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  transition: 700ms;
  z-index: 100;
}

.home-page-buttons:hover {
  cursor: pointer;
  background-color: #fa5805;
  color: white;
}

@media screen and (max-width: 600px) {
  .o-atlas-body {
    height: 100%;
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: center;
  }
  .o-atlas-left-container {
    display: none !important;
  }
  .small-cell-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .o-atlas-page-buttons {
    display: none !important;
  }
  .o-atlas-page-buttons-selected {
    display: none !important;
  }
  .big-cell-wrapper {
    display: none;
  }
}

.o-atlas-cell-title {
  font-weight: bold;
}
@media screen and (min-width: 601px) {
  .small-cell-container {
    display: none !important;
  }
}
.o-atlas-body {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex: 12;
}

.o-atlas-left-container {
  width: 60vw;
  flex: 7;
  padding-top: 3%;
}

@media screen and (max-width: 600px) {
  .dispositivo-map-container {
    display: none !important;
  }
}

.dispositivo-map-container {
  width: 60vw;
  padding-top: 3%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.quiz-container {
}

.o-atlas-left-container-details {
  height: 80vh;
  width: 60vw;
  flex: 7;
  display: flex;
  justify-content: center;
  padding-top: 3%;
}

.o-atlas-right-container {
  width: 50vw;
  padding-top: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.o-atlas-page-buttons {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 7px;
  width: 150px;
  padding-bottom: 7px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: transparent;
  border-color: #fa5805;
  border-style: solid;
  border-width: 3px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-family: "quantico";
  transition: 700ms;
}

.o-atlas-page-buttons-selected {
  padding-left: 20px;
  font-family: "quantico";
  padding-right: 20px;
  padding-top: 7px;
  width: 150px;
  padding-bottom: 7px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: #fa5805;
  border-color: #fa5805;
  border-style: solid;
  border-width: 3px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.o-atlas-page-buttons:hover {
  cursor: pointer;
  background-color: #fa5805;
  color: white;
  transition: 400ms;
}

.cities-list-card-body {
  border-color: #57397e;
  border-style: solid;
  border-width: 3px;
  height: 60vh !important;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 81.6%;
  margin-top: 10px;
}

.cities-details-list-card-body::-webkit-scrollbar {
  width: 7px;
}

.cities-details-list-card-body::-webkit-scrollbar-track {
  border-radius: 10px;
}

.cities-details-list-card-body::-webkit-scrollbar-thumb {
  background-color: #fa5805;
  height: 40px;
}

.cities-list-card-body::-webkit-scrollbar {
  width: 7px;
}

.cities-list-card-body::-webkit-scrollbar-track {
  border-radius: 10px;
}

.cities-list-card-body::-webkit-scrollbar-thumb {
  background-color: #fa5805;
  height: 40px;
}

.back-button-wrapper {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: none;
  background-color: #fa5805;
  margin-left: 5%;
  margin-top: 30px;
  z-index: 100 !important;
  position: absolute;
}

.back-button-wrapper:hover {
  cursor: pointer;
}

.back-button-icon {
  color: rgb(255, 255, 255);
  height: 30px;
  width: 30px;
}

.cities-details-body {
  display: flex;
  flex-direction: column;
}

.cities-details-card-wrapper {
  display: flex;
  flex-direction: row;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 4%;
  justify-content: space-between;
}

@media screen and (max-width: 600px) {
  .cities-details-card-wrapper {
    flex-direction: column !important;
    align-items: center;
  }
  .cities-details-left-list-card-body {
    align-self: center;
    border-color: #57397e;
    border-style: solid;
    border-width: 3px;
    height: 60vh !important;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 90vw;
    margin-top: 10px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cities-details-list-card-body {
    width: 90vw !important;
    border-color: #57397e;
    border-style: solid;
    border-width: 3px;
    height: 60vh !important;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 10px;
  }
}

@media screen and (min-width: 600px) {
  .cities-details-list-card-body {
    border-color: #57397e;
    border-style: solid;
    border-width: 3px;
    height: 60vh !important;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 45%;
    margin-top: 10px;
  }

  .cities-details-left-list-card-body {
    border-color: #57397e;
    border-style: solid;
    border-width: 3px;
    height: 60vh !important;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 45%;
    margin-top: 10px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.cities-details-title {
  color: black;
  font-weight: bold;
}

.narrativa-list-card-body {
  border: 3px #57397e solid;
  margin-bottom: 25px;
  width: 70%;
  margin-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
}

.narrativas-list-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.narrativa-list-header {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.narrativa-title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 7px;
}

.narrativa-title-divider {
  height: 70%;
  width: 2px;
  border-radius: 5px;
  margin-left: 7px;
  margin-right: 7px;
  background-color: black;
}

.searchbar-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 3%;
}

.searchbar-body {
  width: 74vw;
  height: 30px;
  border: 3px #fa5805 solid;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.searchbar-element {
  width: 97%;
  padding-left: 5px;
  height: 100%;
  border: none;
}

.searchbar-icon {
  height: 80%;
}

.sobre-nos-body {
  display: flex;
  flex: 12;
  flex-direction: row;
}

.sobre-nos-buttons-big {
  flex: 2;
  display: flex;
  flex-direction: column;
  margin-top: 25vh;
  padding-left: 5vw;
}

.sobre-nos-options-big {
  flex: 10;
  display: flex;
  flex-direction: column;
  margin-top: 5vh;
  padding-left: 5vw;
  padding-right: 5vw;
}

.sobre-nos-option-selected-big {
  color: #fa5805;
  transition: 400ms;
}

.sobre-nos-option-selected-big:hover {
  cursor: pointer;
  transition: 400ms;
}

.sobre-nos-option-big {
  transition: 400ms;
}

.sobre-nos-option-big:hover {
  color: #fa5805;
  transition: 400ms;
  cursor: pointer;
}

.input-sobre-nos {
  border: 2px #fa5805 solid;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 7px;
  font-family: quantico;
  margin-bottom: 20px;
  align-self: center;
  width: 97%;

  background-color: transparent;
}

.sobre-nos-element-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-right: 4px;
  padding-left: 4px;
}

@media screen and (max-width: 500px) {
  .sobre-nos-element-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-right: 4px;
    padding-left: 4px;
  }
  .sobre-nos-element-body {
    background-color: #57397e;
    margin-bottom: 15px;
    margin-left: 5px;
    margin-right: 5px;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    padding: 12px;
    width: 100% !important;
    height: 180px;
  }
  .sobre-nos-element-image {
    background-color: #57397e;
    margin-bottom: 15px;
    margin-left: 5px;
    margin-right: 5px;
    overflow-y: hidden;
    overflow-x: hidden;
    padding-right: 9.5px;
    width: 100% !important;
    height: 180px;
  }
}

.sobre-nos-element-body {
  background-color: #57397e;
  margin-bottom: 15px;
  margin-left: 5px;
  margin-right: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-wrap: wrap;
  padding: 12px;
  width: 250px;
  height: 156px;
}
.sobre-nos-element-image {
  margin-bottom: 15px;
  overflow-y: hidden;
  overflow-x: hidden;
  padding-right: 9.5px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 250px;
  height: 180px;
  background-color: #57397e;
  z-index: 10;
  cursor: pointer;
}

.card-filp-sobre-nos {
  background-color: #57397e;
  margin-bottom: 15px;
  margin-left: 5px;
  margin-right: 5px;
  overflow-y: hidden;
  overflow-x: hidden;
  padding-right: 9.5px;
  width: 250px;
  height: 180px;
  cursor: pointer;
}

.sobreNos-overflow-text {
  margin-bottom: 15px;
  margin-left: 5px;
  margin-right: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 9.5px;
  width: 250px;
  height: 180px;
  cursor: pointer;
}
.sobreNos-overflow-text::-webkit-scrollbar-track {
  border-radius: 10px;
}

.sobreNos-overflow-text::-webkit-scrollbar-thumb {
  background-color: #fa5805;
  height: 40px;
}

.sobreNos-overflow-text::-webkit-scrollbar {
  width: 7px;
}

.sobreNos-overflow-text::-webkit-scrollbar-track {
  border-radius: 10px;
}

.sobreNos-overflow-text::-webkit-scrollbar-thumb {
  background-color: #fa5805;
  height: 40px;
}

.sobreNos-text-wrapper {
  padding-left: 5px;
  padding-right: 5px;
}
.sobre-nos-element-body::-webkit-scrollbar {
  width: 7px;
}

.sobre-nos-element-body::-webkit-scrollbar-track {
  border-radius: 10px;
}

.sobre-nos-element-body::-webkit-scrollbar-thumb {
  background-color: #fa5805;
  height: 40px;
}

.sobre-nos-element-body::-webkit-scrollbar {
  width: 7px;
}

.sobre-nos-element-body::-webkit-scrollbar-track {
  border-radius: 10px;
}

.sobre-nos-element-body::-webkit-scrollbar-thumb {
  background-color: #fa5805;
  height: 40px;
}

.sobre-nos-element-body img {
  align-self: center;
  width: 100%;
  height: auto;
}
.sobre-nos-element-body :hover {
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .sobre-nos-element-body {
    background-color: #57397e;
    margin-bottom: 15px;
    margin-left: 5px;
    margin-right: 5px;
    padding: 12px;
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .sobre-nos-body {
    display: flex;
    flex-direction: column;
  }

  .sobre-nos-buttons-big {
    display: none !important;
  }

  .sobre-nos-option-selected-big {
    background-color: #fa5805;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    transition: 400ms;
  }

  .sobre-nos-option-selected-big:hover {
    cursor: pointer;
    transition: 400ms;
  }

  .sobre-nos-option-big {
    transition: 400ms;
    height: 15px;
    border-radius: 50%;
    width: 15px;
    background-color: #454444;
  }

  .sobre-nos-option-big:hover {
    color: #fa5805;
    transition: 400ms;
    cursor: pointer;
  }
  .sobre-nos-selected-indicator {
    display: flex !important;
    margin-top: 30px;
    justify-content: center;
    align-items: center;
  }
}

.sobre-nos-buttons-small {
  display: flex;
  flex-direction: row;
  align-self: center;
  width: 75px;
  align-items: center;
  justify-content: space-evenly;
}

.sobre-nos-selected-indicator {
  display: none;
}

.bottom-buttons-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 25px;
  width: 99%;
  margin-bottom: 20px;
  position: fixed;
  bottom: 0px;
  justify-content: flex-end;
  z-index: 0 !important;
}

.button-bottom-wrapper {
  background-color: #fa5805;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-top: 5px;
  margin-left: 15px;
}

.button-bottom-wrapper-collapsed {
  background-color: #fa5705c6;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-top: 5px;
  margin-left: 15px;
}

.button-bottom-wrapper-collapsed a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button-bottom-wrapper-collapsed img {
  height: 70% !important;
  width: auto;
}

.button-bottom-wrapper:hover {
  background-color: #fa5705c6;
  transition: 400ms;
  cursor: pointer;
}
.button-bottom-wrapper-collapsed:hover {
  background-color: #fa5705;
  transition: 400ms;
  cursor: pointer;
}
.collapse-css-transition {
  transition: height 400ms cubic-bezier(0.4, 0, 0.2, 1);
}

.collapsed-buttons-wrapper {
  display: flex;
  flex-direction: column;
  position: static;
  margin-left: -10px !important;
}

.city-item-divider {
  padding-left: 3vw;
  display: flex;
  flex-direction: column;
}

.bottom-city-item-divider {
  height: 3px;
  width: 90%;
  align-self: center;
  background-color: #fa5705;
  margin-bottom: 20px;
}

.bottom-city-item-divider-new-content {
  height: 3px;
  width: 100%;
  align-self: center;
  background-color: #fa5705;
  margin-bottom: 20px;
}

.row-city-item-input {
  display: flex;
  flex-direction: row;
}

.row-city-item-input div {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.city-item-image {
  max-width: 30vw;
  padding-left: 15px;
  margin-bottom: 20px;
}

.cronologia-pensamento-input {
  width: 524px;
  border: 2px solid #57397e;
  background-color: transparent;
  margin-bottom: 5vh;
  height: 30px;
  padding-left: 5px;
  font-family: quantico;
}

.filter-button-wrapper {
  position: absolute;
  height: 70vh;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  margin-left: 4vw;
}

.filter-button-container {
  background-color: #fa5705;
  border: 2px #fa5705 solid;
  width: 150px;
  color: #fff;
  font-family: quantico;
  display: flex;
  padding-left: 5px;
  padding-top: 7px;
  padding-bottom: 7px;
  justify-content: space-between;
  padding-right: 5px;
  align-items: center;
}

.filter-button-container:hover {
  cursor: pointer;
}

.filter-button-container-item {
  border: 2px #fa5705 solid;
  background-color: #fff;
  width: 150px;
  padding-left: 5px;
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
  align-items: center;
  padding-top: 7px;
  padding-bottom: 7px;
  font-family: quantico;
}

.filter-button-container-item:hover {
  cursor: pointer;
}
.filter-button-options-wrapper {
  display: inline-block;
  position: relative;
  flex-direction: column;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  padding-left: 7px;
  margin-left: 150px;
  margin-top: -36px;
  padding-right: 7px;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown-content-reverse {
  display: flex;
  position: absolute;
  flex-direction: column;
  column-count: 4;
  column-gap: 10px;
  background-color: #fff;
  padding-left: 7px;
  margin-left: 150px;
  margin-top: -36px;
  padding-right: 7px;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content-big {
  display: none;
  position: absolute;
  background-color: #fff;
  padding-left: 7px;
  margin-left: 150px;
  margin-top: -36px;
  padding-right: 25px;
  width: 260px !important;
  white-space: nowrap;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.filter-button-white-arrow {
}

.filter-button-orange-arrow {
}

.multiple-select-wrapper {
  display: flex;
  flex-direction: column;
  width: 260px;
}

.center-admin-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  align-items: center;
}

.narrativa-author-name {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  color: #454444c2;
}

.big-svg-institutional-images {
  width: 50%;
  height: 100%;
  z-index: 1;
}

.institutional-images-wrapper {
  height: auto;
  width: 120%;
  margin-top: -60px;
  align-items: center;
  display: flex;
}
.institutional-image-cnpq {
  width: 13%;
  margin-bottom: 15px;
  height: 25%;
}

@media screen and (max-width: 600px) {
  .big-svg-institutional-images {
    width: 50%;
    height: 50vh;
  }

  .institutional-images-wrapper {
    height: auto;
    width: 140%;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .institutional-image-cnpq {
    width: 13.8%;
    height: 20%;
  }
}
