body {
  margin: 0;
  font-family: 'quatico';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 50px;
}

@font-face{
  font-family: 'quantico';
  src: local('quantico'), url('./assets/fonts/Quantico/Quantico-Regular.ttf');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
